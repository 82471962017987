html,
body {
  height: 100vh;
}

.previewPane {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
}

.table-container {
  max-height: 500px;
  overflow: scroll;
}

.table-container td {
  max-height: 30px; /* Set your desired maximum height */
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tall {
  min-height: 400px;
}

body > footer {
  position: sticky;
  top: 100vh;
}

footer {
  padding-top: 50px;
  margin-top: 100px;
  background: rgba(0, 0, 0, 0.1);
}

.footerContainer {
  max-width: 480px;
  text-align: center;
  margin: 0 auto;
}

.openAIExample {
  word-wrap: break-word;
}

.data-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.i-button[data-tooltip] {
  border: 0;
  margin-left: 4px;
  font-size: 14px;
}

[data-tooltip]::before {
  /* Enable word wrapping */
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  /* Allow long words to break */
  word-break: break-word;
  /* Ensure the content doesn't overflow */
  overflow: hidden;
  /* Optional: hyphenate words when breaking */
  hyphens: auto;
}

/* Adjust positioning if needed */
[data-tooltip]::before,
[data-tooltip]::after {
  width: max-content;
  max-width: 320px;
}

.parse-error {
  background: rgba(255, 0, 0, 0.2);
}
